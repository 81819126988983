import styles from './canceldialbtn.module.scss'
import { ImPhoneHangUp } from 'react-icons/im'
import { useSelector } from 'react-redux'
import { Call_Status, cancelCallTypes, path, serverFeild } from '../../../utils/Constants'
import { useNavigate } from 'react-router-dom'
import { FirebasePushNotifReq } from '../../../services/requests'
const CancelDialBtn = ({ palcode, talkId, mediaDomain, callStatus }) => {
  const { partner_account_id } = useSelector(state => state.auth);
  const navigate = useNavigate()
  const onClickHandler = () => {
    // const server2 = 'mqtt://emqx.palphone.com:/mqtt'; // WebSocket URL
    // const options = {
    //   clientId: 'emqx_react_' + Math.random().toString(16).substring(2, 8), // A unique client identifier
    //   username: accessToken, // Your MQTT username
    //   protocol: 'mqtt',
    //   port: 8083
    // };
    // const client = mqtt.connect(server2, options);
    // // Set up event handlers for MQTT client
    // client.on('connect', () => {
    //   console.log('Connected to MQTT broker in cancel talk');
    // })
    // client.on('disconnect', (err) => {
    //   if (err) {
    //     console.error(`MQTT connection error: ${err}`);
    //   }
    //   setTimeout(() => client.reconnect(), 5000);
    // });
    // console.log('palcode', palcode);
    // const message = { "content": { "callId": talkId }, "type": "cancelCallRequest" }
    // const stringifiedMessage = JSON.stringify(message);
    // if (client) {
    //   client.publish(`action-${accountId}-${partner_account_id}`, stringifiedMessage, (err) => {
    //     if (!err) {
    //       console.log(`Published message to action-${accountId}-${partner_account_id} topic: ${stringifiedMessage}`);

    //     } else {
    //       console.error('Error publishing message:', err);
    //     }
    //   })
    // }
    if (talkId && mediaDomain) {
      FirebasePushNotifReq('/v1/firebase', {
        type: cancelCallTypes.CANCELCALL,
        pal_account_id: partner_account_id,
        media_domain: mediaDomain,
        talk_id: talkId,
      }).catch(error => {
        console.log('there is error in push notif', error);
      })
      if (callStatus === Call_Status.RINGING) {
        if (palcode.length === 6) {
          FirebasePushNotifReq('/v1/firebase', {
            type: cancelCallTypes.RINGINGMISSEDCALL,
            pal_account_id: partner_account_id,
            media_domain: mediaDomain,
            talk_id: talkId,
            pal_code: palcode,
            platform: serverFeild.Platform,
            ttlInMinutes: (3 * 1440)
          }).catch(error => {
            console.log('there is error in push notif', error);
          })
        } else {
          FirebasePushNotifReq('/v1/firebase', {
            type: 'ringMissedCallByPalNumber',
            pal_account_id: partner_account_id,
            media_domain: mediaDomain,
            talk_id: talkId,
            platform: serverFeild.Platform,
            ttlInMinutes: (3 * 1440),
            pal_name: 'palphoneWeb'
          }).catch(error => {
            console.log('there is error in push notif', error);
          })
        }

      }
    }
    navigate(path.Home)
  }

  return (

    <div className={styles.cancelBtn}>
      <button onClick={onClickHandler} >
        <ImPhoneHangUp size={40} color='white' />
      </button>
    </div>
  )
}
export default CancelDialBtn