import React from 'react'
import styles from './spinner.module.scss'

const Spinner = () => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.ldsRoller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

  )
}

export default Spinner