import { useNavigate } from "react-router-dom"
import { Process_Status } from "../../utils/Constants"

const EndCall = ({ callManager, setProcessChanger }) => {
  console.log('/endCall');
  const navigate = useNavigate()
  const navigateHome = () => {
    callManager.endCall({ sendReqToExitRoom: false, isForce: true, closeTheWs: true, calledFrom: "talking page", closeTheWSCore: true })
    setProcessChanger(Process_Status.CREATINGCALL)
    navigate('/')
  }
  return (
    <>
      <h1>End Call Page</h1>
      <button onClick={navigateHome}>Home</button>
    </>
  )
}
export default EndCall