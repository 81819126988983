import styles from './keypadbtn.module.scss'
const KeypadBtn = ({ label, onclick }) => {
  return (
    <div className={styles.keypadBtnContainer}>
      <button onClick={() => onclick(label)}>
        {label}
      </button>
    </div>
  )
}
export default KeypadBtn