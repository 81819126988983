import { Call_Status } from '../../utils/Constants'
import RingSound from '../ringSound/RingSound'
import styles from './callstatus.module.scss'
import connecting from '../../assets/images/connect.gif'
import ringing from '../../assets/images/ringing.gif'
import Timer from '../timer/Timer'

const CallStatus = ({ currentStatus, palcode }) => {
  // console.log('carrent status', currentStatus);
  // console.log('palcode', palcode);
  const displayJsx = (CallStatus) => {
    switch (CallStatus) {
      case Call_Status.CONNECTING:
        return <div className={styles.callingStateContainer}>
          <p>Catching up with your pal!</p>
          <div className={styles.callingStateContent} >
            <p>Connecting To Server...</p>
          </div>
        </div>

      case Call_Status.CALLING:
        return <div className={styles.callingStateContainer}>
          <p>Catching up with your pal!</p>
          <div className={styles.callingStateContent} >
            <p>Calling...</p>
            <img src={connecting} alt='connecting' />
          </div>
        </div>
      case Call_Status.RINGING:
        return <div className={styles.callingStateContainer}>
          <p>Catching up with your pal!</p>
          <div className={styles.callingStateContent} >
            <p>Ringing...</p>
            <img src={ringing} alt='ringing' />
            <RingSound />
          </div>
        </div>
      case Call_Status.REJECTED:
        return <div className={styles.callingStateContainer}>
          <p>Maybe Another Time!</p>
          <div className={styles.callingStateContent} >
            <p style={{ color: 'red' }}>Declined...</p>
          </div>
        </div>
      case Call_Status.NORESPONSE:
        return <div className={styles.callingStateContainer}>
          <p>Maybe Another Time!</p>
          <div className={styles.callingStateContent} >
            <p >No response</p>
          </div>
        </div>
      case 'callStarted':
        return <div className={styles.talkingStateContainer}>

          <div className={styles.talkingStateContent} >
            <div>
              <p >Secure Conversation with </p>
              <p>{palcode.length === 6 ? palcode : `+786-${palcode}`}</p>
            </div>
            <Timer />
          </div>
          <div className={styles.talkingStatus}>
            <p style={{ opacity: '0' }}>talking...</p>
          </div>
        </div>
      case 'creatingCall':
        return <div className={styles.talkingStateContainer}>

          <div className={styles.talkingStateContent} >
            <div>
              <p >Secure Conversation with </p>
              <p>{palcode.length === 6 ? palcode : `+786-${palcode}`}</p>
            </div>
            {/* <Timer /> */}
          </div>
          <div className={styles.talkingStatus}>
            <p>Connecting...</p>
          </div>
        </div>
      case 'exitRoom':
        return <div className={styles.talkingStateContainer}>
          <div className={styles.talkingStateContent} >
          </div>
          <div className={styles.talkingStatus}>
            <p>End Call</p>
            <p>{palcode.length === 6 ? palcode : `+786-${palcode}`}</p>
          </div>
        </div>
      case 'apiError':
        return <div className={styles.talkingStateContainer}>
          <div className={styles.talkingStateContent} >
          </div>
          <div className={styles.talkingStatus}>
            <p>server in not reachable...</p>
            <p style={{ opacity: '0' }}>{palcode.length === 6 ? palcode : `+786-${palcode}`}</p>
          </div>
        </div>
      default:
        <div className={styles.callingStateContainer}>
          <p>Catching up with your pal!</p>
          <div className={styles.callingStateContent} >
            <p>Connecting To Server...</p>
          </div>
        </div>
    }
  }
  return (
    <>
      {displayJsx(currentStatus)}
    </>
  )
}
export default CallStatus