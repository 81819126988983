import axios from "axios";
import { http, http2, media } from "./axios-service";


export const testApi = async (url, params, config = {}) => {
  const response = await http.get(url, params, config).catch((err) => err);
  if (response) return response;
};

//getting Account id and tokens
export const GetAccountId = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response.data;
  } catch (error) {
    // You can handle errors here if needed
    console.error('Error in postApi:', error);
    throw error;
  }
};

export const getReq = async (url, params, config = {}) => {
  const response = await http.get(url, params, config).catch((err) => err);
  if (response) return response;
};
//Lets call
export const LetsCallReq = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response.data
  } catch (error) {

    console.error('Error in postApi:', error);
    throw error;
  }
};
//push call
export const FirebasePushNotifReq = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response
  } catch (error) {

    console.error('Error in postApi:', error);
    throw error;
  }
};

export const getRefreshTokenReq = async (url, params, config = {}) => {
  const response = await http2.get(url, params, config).catch((err) => err);
  if (response) return response;
};

//create room
export const getMediaIp = async (url, params, config = {}) => {
  const response = await media.get(url, params, config).catch((err) => err);
  if (response) return response;
};
export const HttpPostReq = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response
  } catch (error) {

    console.error('Error in postApi:', error);
    throw error;
  }
};


export const getPreSignUrl = async (url, params = {}, config = {}) => {
  // Construct query string from the params object
  const queryParams = new URLSearchParams(params).toString();

  // Append query string to the base URL
  const finalUrl = `${url}?${queryParams}`;

  // Make the GET request with the constructed URL
  const response = await http.get(finalUrl, config).catch((err) => err);

  if (response) return response;
};

// export const updateData = (url , data) => {
//   console.log('5555',data);
  
//   axios.put(url, data)
//     .then(response => {
//       console.log('Data updated successfully:', response.data);
//       console.log('Data updated successfully:', response.headers.etag);
//       return response.headers.etag

//     })
//     .catch(error => {
//       console.error('There was an error updating the data!', error);
//     });
// };
export const updateData = (url, data) => {
  return axios.put(url, data)
    .then(response => {    
      return response.headers.etag; // Return the ETag value
    })
    .catch(error => {
      console.error('There was an error updating the data!', error);
      throw error; // Re-throw the error so it can be caught later
    });
};

export const finishUploading = async (url, data, config = {}) => {
  try {
    const response = await http.post(url, data, config);
    return response
  } catch (error) {

    console.error('Error in postApi:', error);
    throw error;
  }
};
export const DeleteUploadedFile = async (url, params = {}, config = {}) => {
  // Construct query string from the params object
  const queryParams = new URLSearchParams(params).toString();

  // Append query string to the base URL
  const finalUrl = `${url}?${queryParams}`;

  // Make the GET request with the constructed URL
  const response = await http.delete(finalUrl, config).catch((err) => err);

  if (response) return response;
};