// CircularProgress.jsx
import React from 'react';
import styles from './CircularProgress.module.scss';

const CircularProgress = ({ progress }) => {
  
  const radius = 50;
  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (progress / 100) * circumference;

  return (
    <div className={styles.wrapper}>
      <svg
        className={styles.svg}
        width="60"
        height="60"
        viewBox="0 0 120 120"
      >
        <circle
          className={styles.backgroundCircle}
          cx="60"
          cy="60"
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className={styles.progressCircle}
          cx="60"
          cy="60"
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
        />
      </svg>
      <div className={styles.label}>{progress}%</div>
    </div>
  );
};

export default CircularProgress;
