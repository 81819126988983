import styles from './characterAvatar.module.scss'
import unkownImage from '../../assets/images/unknown.jpg'
import { url } from '../../utils/Constants'
import { useSelector } from 'react-redux';
const CharacterAvatar = ({ characterId, title }) => {
  console.log('character id', characterId);
  
  const { partner_name } = useSelector(state => state.auth);
  return (
    <div className={styles.avatarWrapper}>
      <img src={`${url}/static/characters/${characterId}/new-avatar.png`} alt="palphone_character_avatar" onError={(e) => (e.target.src = unkownImage)} />
      {title && <p>{title.length === 6 ? title : `+786-${title}`}</p>}
      {partner_name && <span >{partner_name}</span>}

    </div>
  )
}
export default CharacterAvatar