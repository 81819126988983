import styles from './textmessage.module.scss'
import { isPersianOrArabic } from "../../../utils/helperFunctions";
import { messageStatus } from "../../chat/Chat";


const TextMessage = ({ msg }) => {
    return (
      <div className={`message ${msg.sender === 'me' ? styles.myMessage : styles.partnerMessage}`} style={{ textAlign: isPersianOrArabic(msg.text) ? 'right' : 'left' }}>
        {msg.text.split('\n').map((line, index) => (
          <span key={index}>{line}</span>
        ))}
        <p>{msg.showTime} <strong>{msg.sender === 'me' && messageStatus(msg.status)}</strong></p>
      </div>
    );
  };
  
  export default TextMessage;