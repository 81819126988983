import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './redux/slice';
import { BrowserRouter } from 'react-router-dom';

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' })
//     .then((registration) => {
//       // Service worker registration successful
//       // console.log('Firebase service worker registered: ', registration);
//     })
//     .catch((error) => {
//       // Service worker registration failed
//       console.error('Firebase service worker registration failed: ', error);
//     });
// }
if ('serviceWorker' in navigator) {

  window.addEventListener('load', () => {

    navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {

      console.log('ServiceWorker registration successful with scope: ', registration.scope);
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New update available
              alert('New version installed')
              window.location.reload();
              // if (confirm('New version available. Refresh to update?')) {
              //   window.location.reload();
              // }
            }
          }
        };
      };
    }).catch((error) => {
      console.log('ServiceWorker registration failed: ', error);
    });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <ToastContainer position="top-center" />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);


