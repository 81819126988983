import styles from './modal.module.scss'
const Modal = ({ isOpen, children }) => {
  return isOpen && (<div className={styles.modalOverlay}>
    <div className={styles.modalContainer}>
      {children}
    </div>
  </div>

  )

}
export default Modal