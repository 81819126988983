// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.accessToken);
  // console.log('isAthenticate', isAuthenticated);

  return isAuthenticated.length > 10 ? (
    children
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
