import Modal from '../modal/Modal'
import styles from './filedownloading.module.scss'
import { RiDownload2Fill } from "react-icons/ri";
import { AiOutlineClose  } from 'react-icons/ai'
const FileDownloading=({isOpen,closeModal,mediaurl,type})=>{
    // console.log('urllllllllll',mediaurl);
    
    return (
        <Modal isOpen={isOpen} isClose={closeModal}>
                <div className={styles.imgContainer}>
                   {type==='photo' ?  <img src={mediaurl} alt="" />
                    :  <video crossOrigin="anonymous" controls src={mediaurl} className={styles.video} style={{ borderRadius: '15px', maxWidth: '800px', maxHeight: '800px' }} preload="auto" />

                   }
                </div>
                <div className={styles.actionContainer}>
                <a id="download-link" href={mediaurl} download style={{ display: 'none' }}></a>
                <button  onClick={() => { const downloadLink = document.getElementById('download-link');
                    downloadLink.click(); // Programmatically click the anchor tag
                }}
>
                    Download
                </button>
                <button onClick={closeModal}>Close</button>   
                </div> 
                 {/* <button onClick={closeModal}>close</button>
                <a href={mediaurl} download>
                <button>Download</button>
            </a>  */}
        </Modal>
    )
}
export default FileDownloading