
import { Profiler, useEffect, useState, useRef } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom'
import Home from './pages/home/Home';
import { Encryption_Keys, Process_Status, cookiesLabel, path, serverFeild } from './utils/Constants';
import { setAccessTokens, setAccountId, setDeviceId, setFirebaseTokens, setRefreshTokens, setSessionId } from './redux/slice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { GetAccountId, getRefreshTokenReq } from './services/requests';
import { SaveInCookies, decodeToken, getFromCookies } from './utils/helperFunctions';
import PrivateRoute from './components/privateRoute/PrivateRoute'
import CallingState from './components/callingState/CallingState';
import Reject from './pages/reject/Reject';
import Talking from './pages/talking/Talking';
import { callManager } from './utils/callManenger'
import EndCall from './pages/endcall/EndCall';
import Modal from './components/modal/Modal';
import { AiOutlineClose } from 'react-icons/ai'
import PwaInstructor from './pages/pwaInstructor/PwaInstructor';
import RotationWarning from './pages/rotationWarning/RotationWarning';
import { MyMessage } from './protobuftest/test_pb';
function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [cookiesAccessToken, setCookiesAccessToken] = useState('')
  const [cookiesRefreshToken, setCookiesRefreshToken] = useState('')
  const [cookiesDeviceId, setCookiesDeviceId] = useState('')
  const [cookiesSessionId, setCookiesSessionId] = useState('')
  const [cookiesFirebaseToken, setCookiesFirebaseToken] = useState('')
  const [processStatus, setProcessStatus] = useState(Process_Status.CREATINGCALL)
  const [notificationPermission, setNotificationPermission] = useState('');
  const [orientation, setOrientation] = useState('portrait-primary');
  // const [microphonePermission, setMicrophonePermission] = useState('')
  // const [showModal, setShowModal] = useState(false);
  const [isSafari, setIsSafari] = useState(false)
  const audioParentEl = useRef(null)
  const setProcessChanger = (status) => {
    setProcessStatus(status)
  }
  callManager.setMakeAwareDispatcher(setProcessChanger)
  const checkNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);
  };
  // const checkMicrophonePermission = async () => {
  //   try {
  //     await navigator.mediaDevices.getUserMedia({ audio: true });
  //     setMicrophonePermission('granted');
  //   } catch (error) {
  //     setMicrophonePermission('denied');
  //   }
  // };
  const handleOrientationChange = () => {
    const userAgent = window.navigator.userAgent;
    // Check if the user agent contains the string "iPhone" or "iPad"
    const isIOS = /iPhone|iPad/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);
    if (isAndroid || isIOS) {
      if (window.screen.orientation && window.screen.orientation.type) {
        setOrientation(window.screen.orientation.type);
      } else {
        // Fallback for browsers that do not support window.screen.orientation
        setOrientation(window.innerWidth > window.innerHeight ? 'landscape-primary' : 'portrait-primary');
      }
      // alert('hi')
      // setOrientation(window.screen.orientation.type);
    }

    // if (window.screen.orientation.type && isIOS && isAndroid) {
    //   setOrientation(window.screen.orientation.type);
    // }

    // alert(window.screen.orientation.type)
  }
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    // Check if the user agent contains the string "iPhone" or "iPad"
    const isIOS = /iPhone|iPad/.test(userAgent);
    // Check if the user is using Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChrome = /chrome/i.test(navigator.userAgent);
    // Check if the app is running as a PWA
    const isPWA = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

    if ((isIOS && isSafari && !isPWA) || (isIOS && isChrome && isPWA)) {
      // User is using Safari
      setIsSafari(true)
    }

  }, []);
  useEffect(() => {
    // Check notification permission
    checkNotificationPermission();
    // // Check microphone permission
    // checkMicrophonePermission();
    const handleBackButton = () => {
      navigate(path.Home)
    };

    window.addEventListener('popstate', handleBackButton);
    // callManager.setAudioParent(audioParentEl)
    callManager.setParentHtmlRef(audioParentEl)
    //------------
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      // console.log('<App.js/> unmounted')
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (notificationPermission === 'denied' || microphonePermission === 'denied') {
  //     console.log('permission denied');
  //     setShowModal(true);
  //   }
  // }, [notificationPermission, microphonePermission]);

  //get cookie information and svae it in redux
  useEffect(() => {
    const accountid = getFromCookies(cookiesLabel.ACCONTID, Encryption_Keys.ACCOUNT_ID_KEY)
    if (accountid) {
      dispatch(setAccountId(Number(accountid)))
    }

    const accessTokens = getFromCookies(cookiesLabel.ACCESSTOKEN, Encryption_Keys.ACCESS_TOKEN_key)
    if (accessTokens) {
      setCookiesAccessToken(accessTokens)
      dispatch(setAccessTokens(accessTokens))
    }
    const refreshTokens = Cookies.get(cookiesLabel.REFRESHTOKEN)
    if (refreshTokens) {
      setCookiesRefreshToken(refreshTokens)
      dispatch(setRefreshTokens(refreshTokens))
    }
    const deviceid = Cookies.get(cookiesLabel.DEVICEID)
    if (deviceid) {
      setCookiesDeviceId(deviceid)
      dispatch(setDeviceId(deviceid))
    }
    const sessionid = Cookies.get(cookiesLabel.SESSIONID)
    if (sessionid) {
      setCookiesSessionId(sessionid)
      dispatch(setSessionId(sessionid))
    }
    const firebaseToke = Cookies.get(cookiesLabel.FIREBASETOKEN)
    setCookiesFirebaseToken(firebaseToke)
    dispatch(setFirebaseTokens(firebaseToke))
  }, [dispatch])



  // first, ckeching wheather refresh token is expire , if expire ==> remove all cookies info and redux state info such as account id and tokens , then , if refresh token not expire chkeck wheather access token expire or not , if expire ==> get new tokens and save them in redux and cookies
  useEffect(() => {
    if (cookiesRefreshToken) {
      const decodedToken = decodeToken(cookiesRefreshToken);
      if (decodedToken && decodedToken.exp) {
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const remainingTime = expirationTime - Date.now();
        //in core , should be 7400000
        if (remainingTime < 7400000) {
          console.log('refresh expired');
          GetAccountId('/v1/accounts', {
            device_id: cookiesDeviceId,
            session_id: cookiesSessionId,
            platform: serverFeild.Platform,
            app_version: serverFeild.appVersion,
            update_firebase: false,
            firebase_token: cookiesFirebaseToken
          }).then((res) => {
            console.log(res);
            SaveInCookies(res.tokens.access_token, res.tokens.refresh_token, res.account_id)
            dispatch(setAccessTokens(res.tokens.access_token))
            dispatch(setRefreshTokens(res.tokens.refresh_token))
          }).catch(error => console.log('error in account api'))

        } else {
          //decode and ckeck access code expiration
          if (cookiesAccessToken) {
            const decodedToken = decodeToken(cookiesAccessToken);
            // console.log('decode access', decodedToken);
            if (decodedToken && decodedToken.exp) {
              const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
              const remainingTime = expirationTime - Date.now();
              //if expire time < 4 (14400000ms) hour get new tokens and save it in redux and cookies
              if (remainingTime < 14400000) {
                console.log('access expired');
                getRefreshTokenReq('/v1/token').then((res) => {
                  console.log('res access', res);
                  if (res.status === 200) {
                    SaveInCookies(res.data.tokens.access_token, res.data.tokens.refresh_token)
                    dispatch(setAccessTokens(res.data.tokens.access_token))
                    dispatch(setRefreshTokens(res.data.tokens.refresh_token))
                  }

                }).catch(error => console.log('error in api'))
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesAccessToken, cookiesRefreshToken, dispatch]);
  //---------------
  // useEffect(() => {
  //   // Create a new instance of your protobuf message
  //   const message = new MyMessage();

  //   // Set values for the fields
  //   message.setField1('Hello');
  //   message.setField2(42);

  //   // Serialize the message to a buffer
  //   const buffer = message.serializeBinary();

  //   console.log('Serialized buffer:', buffer);
  //   // Deserialize the message from the buffer
  //   const deserializedMessage = MyMessage.deserializeBinary(buffer);

  //   console.log('Deserialized message:', deserializedMessage.toObject());
  // }, []);

  return (

    <div className="App">
      <div style={{ width: '0', height: '0', visibility: 'hidden', opacity: '0' }} ref={audioParentEl}></div>
      {/* <Modal isOpen={showModal}>
        <div className='modalContainer'>
          <div className='closeBtn'>
            <AiOutlineClose size={25} onClick={() => setShowModal(false)} />
          </div>
          <div className='modalText'>
            <strong >Notification and microphone permission are not yet  enabled</strong>
            <p>
              Notification and microphone permission should be enable for using palphone web.
            </p>
          </div>
          <div className='actionBtn'>
            <button onClick={() => setShowModal(false)}>Ok</button>

          </div>
        </div>

      </Modal> */}
      <Routes>
        <Route path={path.Home} element={isSafari ? <PwaInstructor /> : orientation.includes('portrait') ? <Home callManager={callManager} setProcessChanger={setProcessChanger} /> : <RotationWarning />} />
        {/* <Route path={path.Home} element={<Home callManager={callManager} setProcessChanger={setProcessChanger} />} /> */}

        <Route path={path.CALLING} element={<PrivateRoute><CallingState callManager={callManager} /></PrivateRoute>} />
        {/* <Route path={path.CALLING} element={<PrivateRoute><ProfiledCallingState /></PrivateRoute>} /> */}
        <Route path={path.USERINFORM} element={<PrivateRoute><Reject /></PrivateRoute>} />
        <Route path={path.TALKING} element={<PrivateRoute><Talking callManager={callManager} processStatus={processStatus} setProcessChanger={setProcessChanger} /></PrivateRoute>} />
        <Route path={path.ENDCALL} element={<PrivateRoute><EndCall callManager={callManager} setProcessChanger={setProcessChanger} /></PrivateRoute>} />


        {/* {talkingDynamicRoutCaller(processStatus)} */}
      </Routes>
    </div>

  );
}

export default App;
