import { useSelector } from "react-redux";
import MainLayout from "../../components/layout/mainLayout/MainLayout"
import styles from './reject.module.scss'
import { path } from "../../utils/Constants";
import CallStatus from "../../components/callStatus/CallStatus";
import { useLocation, useNavigate } from "react-router-dom";
import { MdCall } from 'react-icons/md'
import { IoClose } from "react-icons/io5";
import CharacterAvatar from "../../components/characterimage/CharacterAvatar";
const Reject = () => {
  const { partner_character_id } = useSelector(state => state.auth);
  const location = useLocation()
  const navigate = useNavigate()
  const { palcode, callCurrentStatus } = location.state
  //close modal handler
  const closeHandler = () => {
    navigate(path.Home)
  }
  const redialHandler = () => {
    navigate(path.CALLING, { state: palcode })
  }
  return (
    <MainLayout>
      <div className={styles.callingContainer}>
        <CallStatus currentStatus={callCurrentStatus} />
        <CharacterAvatar characterId={partner_character_id} title={palcode} />
        <div className={styles.actionBtn}>
          <button className={styles.closeBtn} onClick={closeHandler}>
            <IoClose size={40} color='white' />
          </button>
          <button className={styles.dialBtn} onClick={redialHandler}>
            <MdCall size={40} color='white' />
          </button>
        </div>
      </div>
    </MainLayout>
  )
}
export default Reject