import { MediaKind, RtpCapabilities, RtpParameters } from "mediasoup-client/lib/RtpParameters";
import axios, { AxiosRequestHeaders } from "axios";
import { DtlsParameters, IceCandidate, IceParameters, SctpParameters, SctpStreamParameters } from "mediasoup-client/lib/types";
import { TRANSPORT_TYPE } from "../utils/models";
import { types } from "mediasoup-client";
//-----------------------------------------new Create Room------------------------------------------------------------------
type AxiosOptionsT = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  url: string
  headers?: AxiosRequestHeaders
  data?: any
}
//   /newCreateRoom    post
export type NewCreateRoomReqT = {
  callId: string,
  name: string,
  isLetsCall?: boolean,
  mediaWasCrashed?: boolean,
  publicKey: string
}
export type NewCreateRoomResT = {
  data?: {
    mediaId: string
    peerId: string
    rtpCapabilities: RtpCapabilities
    callCreatedAt: number
    peerCreatedAt: number
  },
  error: boolean,
  message: string
}
export const sendNewCreateRoom = async (token: string, data: NewCreateRoomReqT, mediaUrl: string) => {

  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/newCreateRoom`,
    headers: {
      Authorization: `Bearer ${ token }`,
      Content_Type: 'application/json'
    }
    ,
    data: {
      ...data
    }
  }
  return await axios(options)
}
//-----------------------------------------join room---------------------------------------------------------------------
export type joinReqT = {
  mediaId: string
  callCreatedAt: number
  callId: string
  name: string
  publicKey: string
}

export type joinRestT = {
  data?: {
    rtpcapabilities: RtpCapabilities
    peerCreatedAt: number
    callCreatedAt: number
  },
  error: boolean
  message: string
}
export const sendJoinReq = async (token: string, data: joinReqT, mediaUrl: string) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/join`,
    headers: { 'Authorization': `Bearer ${ token }`, 'Content-Type': 'application/json' },
    data: {
      ...data
    }
  }
  return await axios(options)
}
//-----------------------------------------create webrtc transport --------------------------------------------------------
export type createWebRtcTransportReqT = {
  mediaId: string
  callCreatedAt: number
  peerCreatedAt: number
  callId: string
  name: string
  rtpCapabilities: RtpCapabilities
}
export type createWebRtcTransportResT = {
  error: boolean
  message: string
  data?: {
    transports: {
      [type: string]: {
        id: string;
        iceParameters: IceParameters;
        iceCandidates: IceCandidate[];
        dtlsParameters: DtlsParameters;
        sctpParameters: SctpParameters
      };
    }
  }

}
export const sendCreateWebrtcTransportReq = async (token: string, data: createWebRtcTransportReqT, mediaUrl: string) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/createWebRtcTransports`,
    headers: {
      Authorization: `Bearer ${ token }`,
      Content_Type: 'application/json'
    },
    data: {
      ...data
    }
  }
  return await axios(options)
}

//-------------------------connectTransport--------------------------------------------------------------------------
export type connectTransportReqT = {
  mediaId: string
  callCreatedAt: number
  peerCreatedAt: number
  callId: string,
  name: string,
  transportId: string,
  dtlsParameters: DtlsParameters
  type: TRANSPORT_TYPE
}
export type connectTransportResT = {
  error: boolean,
  message: string,
  data: {
    type: TRANSPORT_TYPE
    transportId: string,
    success: boolean
  }
}
export const sendConnectWebRtcTransportReq = async (token: string, data: connectTransportReqT, mediaUrl: string) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/connectTransport`,
    headers: {
      Authorization: `Bearer ${ token }`,
      Content_Type: 'application/json'
    },
    data: {
      ...data
    }
  }
  return await axios(options)
}

//-------------------------------------------------produce req--------------------------------------------------------
export type produceReqT = {
  mediaId: string
  callCreatedAt: number
  peerCreatedAt: number
  callId: string,
  name: string
  kind: MediaKind,
  producerTransportId: string,
  rtpParameters: types.RtpParameters,
}
export type produceResT = {
  error: boolean,
  message: string,
  data?: {
    producerId: string
  }
}
export const sendProduceReq = async (token: string, data: produceReqT, mediaUrl: string) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/produce`,
    headers: { 'Authorization': `Bearer ${ token }`, 'Content-Type': 'application/json' },
    data: {
      ...data
    }
  }
  return await axios(options)
}
//--------------------------------------------send produce data---------------------------------------------

export type produceDataReqT = {
  mediaId: string
  callCreatedAt: number
  peerCreatedAt: number
  callId: string,
  name: string,
  producerTransportId: string,
  sctpStreamParameters: SctpStreamParameters,
  label: string,
  protocol: "sctp" | "direct"
  appData: any,
}
export type produceDataResT = {
  error: boolean,
  message: string,
  data?: {
    dataProducerId: string
  }
}

export const sendProduceDataReq = async (token: string, data: produceDataReqT, mediaUrl: string) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/produceData`,
    headers: { 'Authorization': `Bearer ${ token }`, 'Content-Type': 'application/json' },
    data: {
      ...data
    }
  }
  return await axios(options)
}
//---------------------------------------------consume-----------------------------------------------------
export type consumeReqT = {
  callId: string,
  consumerTransportId: string
  producerId: string,
  rtpCapabilities: RtpCapabilities
  otherPeerId: string
  isRemote: boolean
}
export type consumeResT = {
  producerId: string,
  id: string,
  kind: MediaKind,
  rtpParameters: RtpParameters;
  type: string;
  producerPaused: boolean;
  otherPeerId: string
  otherPeerName: string
}
export const sendConsumeReq = async (token: string, data: consumeReqT, mediaUrl: string) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/consume`,
    headers: { 'Authorization': `Bearer ${ token }`, 'Content-Type': 'application/json' },
    data: {
      ...data
    }
  }
  return await axios(options)
}

//-----------------------------------------------exit room request---------------------------------------------------------
export type exitRoomReqT = {
  mediaId: string
  callCreatedAt: number
  peerCreatedAt: number
  callId: string,
  name: string,
  isForce: boolean
}
export type exitRoomResT = {
  error: boolean,
  message: string,
  data?: {
    success: boolean
  }
}
export const sendExitRoomReq = async (token: string, data: exitRoomReqT, mediaUrl: string) => {
  const options: AxiosOptionsT = {
    method: 'POST',
    url: `https://${ mediaUrl }/exitRoom`,
    headers: { 'Authorization': `Bearer ${ token }`, 'Content-Type': 'application/json' },
    data: {
      ...data
    }
  }
  return await axios(options)
}