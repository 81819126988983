// source: v1/proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var request_chat_pb = require('../request/chat_pb.js');
goog.object.extend(proto, request_chat_pb);
var request_firebase_pb = require('../request/firebase_pb.js');
goog.object.extend(proto, request_firebase_pb);
var request_missed_call_pb = require('../request/missed_call_pb.js');
goog.object.extend(proto, request_missed_call_pb);
var request_accept_call_pb = require('../request/accept_call_pb.js');
goog.object.extend(proto, request_accept_call_pb);
var request_call_ringing_pb = require('../request/call_ringing_pb.js');
goog.object.extend(proto, request_call_ringing_pb);
var request_change_media_domain_pb = require('../request/change_media_domain_pb.js');
goog.object.extend(proto, request_change_media_domain_pb);
var request_check_and_set_presence_pb = require('../request/check_and_set_presence_pb.js');
goog.object.extend(proto, request_check_and_set_presence_pb);
var request_check_presence_pb = require('../request/check_presence_pb.js');
goog.object.extend(proto, request_check_presence_pb);
var request_lets_call_pb = require('../request/lets_call_pb.js');
goog.object.extend(proto, request_lets_call_pb);
var request_media_register_pb = require('../request/media_register_pb.js');
goog.object.extend(proto, request_media_register_pb);
var request_remove_retained_chat_pb = require('../request/remove_retained_chat_pb.js');
goog.object.extend(proto, request_remove_retained_chat_pb);
var response_check_queue_pb = require('../response/check_queue_pb.js');
goog.object.extend(proto, response_check_queue_pb);
var response_firebase_pb = require('../response/firebase_pb.js');
goog.object.extend(proto, response_firebase_pb);
var response_lets_call_pb = require('../response/lets_call_pb.js');
goog.object.extend(proto, response_lets_call_pb);
var response_media_register_pb = require('../response/media_register_pb.js');
goog.object.extend(proto, response_media_register_pb);
var response_check_and_set_presence_pb = require('../response/check_and_set_presence_pb.js');
goog.object.extend(proto, response_check_and_set_presence_pb);
var response_check_presence_pb = require('../response/check_presence_pb.js');
goog.object.extend(proto, response_check_presence_pb);
var response_chat_pb = require('../response/chat_pb.js');
goog.object.extend(proto, response_chat_pb);
var response_get_chats_pb = require('../response/get_chats_pb.js');
goog.object.extend(proto, response_get_chats_pb);
var response_new_talk_pb = require('../response/new_talk_pb.js');
goog.object.extend(proto, response_new_talk_pb);
goog.exportSymbol('proto.root.Meta', null, global);
goog.exportSymbol('proto.root.MetaData', null, global);
goog.exportSymbol('proto.root.MetaData.DataCase', null, global);
goog.exportSymbol('proto.root.MetaVersion', null, global);
goog.exportSymbol('proto.root.Response', null, global);
goog.exportSymbol('proto.root.Response.DataCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.root.MetaVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.root.MetaVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.root.MetaVersion.displayName = 'proto.root.MetaVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.root.MetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.root.MetaData.oneofGroups_);
};
goog.inherits(proto.root.MetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.root.MetaData.displayName = 'proto.root.MetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.root.Meta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.root.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.root.Meta.displayName = 'proto.root.Meta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.root.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.root.Response.oneofGroups_);
};
goog.inherits(proto.root.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.root.Response.displayName = 'proto.root.Response';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.root.MetaVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.root.MetaVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.root.MetaVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.MetaVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionNumber: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.root.MetaVersion}
 */
proto.root.MetaVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.root.MetaVersion;
  return proto.root.MetaVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.root.MetaVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.root.MetaVersion}
 */
proto.root.MetaVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersionNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.root.MetaVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.root.MetaVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.root.MetaVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.MetaVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 version_number = 1;
 * @return {number}
 */
proto.root.MetaVersion.prototype.getVersionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.root.MetaVersion} returns this
 */
proto.root.MetaVersion.prototype.setVersionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.root.MetaData.oneofGroups_ = [[11,12,13,14,15,16,17,18,19,20,21]];

/**
 * @enum {number}
 */
proto.root.MetaData.DataCase = {
  DATA_NOT_SET: 0,
  CHAT: 11,
  FIREBASE: 12,
  ACCEPT_CALL: 13,
  CALL_RINGING: 14,
  CHANGE_MEDIA_DOMAIN: 15,
  CHECK_PRESENCE: 16,
  CHECK_AND_SET_PRESENCE: 17,
  MEDIA_REGISTER: 18,
  LETSCALL: 19,
  REMOVE_RETAINED_CHATS: 20,
  MISSED_CALL: 21
};

/**
 * @return {proto.root.MetaData.DataCase}
 */
proto.root.MetaData.prototype.getDataCase = function() {
  return /** @type {proto.root.MetaData.DataCase} */(jspb.Message.computeOneofCase(this, proto.root.MetaData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.root.MetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.root.MetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.root.MetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.MetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    receiverId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    chat: (f = msg.getChat()) && request_chat_pb.Chat.toObject(includeInstance, f),
    firebase: (f = msg.getFirebase()) && request_firebase_pb.FirebaseNotification.toObject(includeInstance, f),
    acceptCall: (f = msg.getAcceptCall()) && request_accept_call_pb.AcceptCall.toObject(includeInstance, f),
    callRinging: (f = msg.getCallRinging()) && request_call_ringing_pb.CallRinging.toObject(includeInstance, f),
    changeMediaDomain: (f = msg.getChangeMediaDomain()) && request_change_media_domain_pb.ChangeMediaDomain.toObject(includeInstance, f),
    checkPresence: (f = msg.getCheckPresence()) && request_check_presence_pb.CheckPresence.toObject(includeInstance, f),
    checkAndSetPresence: (f = msg.getCheckAndSetPresence()) && request_check_and_set_presence_pb.CheckAndSetPresence.toObject(includeInstance, f),
    mediaRegister: (f = msg.getMediaRegister()) && request_media_register_pb.MediaRegister.toObject(includeInstance, f),
    letscall: (f = msg.getLetscall()) && request_lets_call_pb.LetsCall.toObject(includeInstance, f),
    removeRetainedChats: (f = msg.getRemoveRetainedChats()) && request_remove_retained_chat_pb.RemoveRetainedChats.toObject(includeInstance, f),
    missedCall: (f = msg.getMissedCall()) && request_missed_call_pb.MissedCall.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.root.MetaData}
 */
proto.root.MetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.root.MetaData;
  return proto.root.MetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.root.MetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.root.MetaData}
 */
proto.root.MetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSenderId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReceiverId(value);
      break;
    case 11:
      var value = new request_chat_pb.Chat;
      reader.readMessage(value,request_chat_pb.Chat.deserializeBinaryFromReader);
      msg.setChat(value);
      break;
    case 12:
      var value = new request_firebase_pb.FirebaseNotification;
      reader.readMessage(value,request_firebase_pb.FirebaseNotification.deserializeBinaryFromReader);
      msg.setFirebase(value);
      break;
    case 13:
      var value = new request_accept_call_pb.AcceptCall;
      reader.readMessage(value,request_accept_call_pb.AcceptCall.deserializeBinaryFromReader);
      msg.setAcceptCall(value);
      break;
    case 14:
      var value = new request_call_ringing_pb.CallRinging;
      reader.readMessage(value,request_call_ringing_pb.CallRinging.deserializeBinaryFromReader);
      msg.setCallRinging(value);
      break;
    case 15:
      var value = new request_change_media_domain_pb.ChangeMediaDomain;
      reader.readMessage(value,request_change_media_domain_pb.ChangeMediaDomain.deserializeBinaryFromReader);
      msg.setChangeMediaDomain(value);
      break;
    case 16:
      var value = new request_check_presence_pb.CheckPresence;
      reader.readMessage(value,request_check_presence_pb.CheckPresence.deserializeBinaryFromReader);
      msg.setCheckPresence(value);
      break;
    case 17:
      var value = new request_check_and_set_presence_pb.CheckAndSetPresence;
      reader.readMessage(value,request_check_and_set_presence_pb.CheckAndSetPresence.deserializeBinaryFromReader);
      msg.setCheckAndSetPresence(value);
      break;
    case 18:
      var value = new request_media_register_pb.MediaRegister;
      reader.readMessage(value,request_media_register_pb.MediaRegister.deserializeBinaryFromReader);
      msg.setMediaRegister(value);
      break;
    case 19:
      var value = new request_lets_call_pb.LetsCall;
      reader.readMessage(value,request_lets_call_pb.LetsCall.deserializeBinaryFromReader);
      msg.setLetscall(value);
      break;
    case 20:
      var value = new request_remove_retained_chat_pb.RemoveRetainedChats;
      reader.readMessage(value,request_remove_retained_chat_pb.RemoveRetainedChats.deserializeBinaryFromReader);
      msg.setRemoveRetainedChats(value);
      break;
    case 21:
      var value = new request_missed_call_pb.MissedCall;
      reader.readMessage(value,request_missed_call_pb.MissedCall.deserializeBinaryFromReader);
      msg.setMissedCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.root.MetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.root.MetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.root.MetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.MetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getReceiverId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getChat();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      request_chat_pb.Chat.serializeBinaryToWriter
    );
  }
  f = message.getFirebase();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      request_firebase_pb.FirebaseNotification.serializeBinaryToWriter
    );
  }
  f = message.getAcceptCall();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      request_accept_call_pb.AcceptCall.serializeBinaryToWriter
    );
  }
  f = message.getCallRinging();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      request_call_ringing_pb.CallRinging.serializeBinaryToWriter
    );
  }
  f = message.getChangeMediaDomain();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      request_change_media_domain_pb.ChangeMediaDomain.serializeBinaryToWriter
    );
  }
  f = message.getCheckPresence();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      request_check_presence_pb.CheckPresence.serializeBinaryToWriter
    );
  }
  f = message.getCheckAndSetPresence();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      request_check_and_set_presence_pb.CheckAndSetPresence.serializeBinaryToWriter
    );
  }
  f = message.getMediaRegister();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      request_media_register_pb.MediaRegister.serializeBinaryToWriter
    );
  }
  f = message.getLetscall();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      request_lets_call_pb.LetsCall.serializeBinaryToWriter
    );
  }
  f = message.getRemoveRetainedChats();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      request_remove_retained_chat_pb.RemoveRetainedChats.serializeBinaryToWriter
    );
  }
  f = message.getMissedCall();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      request_missed_call_pb.MissedCall.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 sender_id = 2;
 * @return {number}
 */
proto.root.MetaData.prototype.getSenderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 receiver_id = 3;
 * @return {number}
 */
proto.root.MetaData.prototype.getReceiverId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.setReceiverId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional request.Chat chat = 11;
 * @return {?proto.request.Chat}
 */
proto.root.MetaData.prototype.getChat = function() {
  return /** @type{?proto.request.Chat} */ (
    jspb.Message.getWrapperField(this, request_chat_pb.Chat, 11));
};


/**
 * @param {?proto.request.Chat|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setChat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearChat = function() {
  return this.setChat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasChat = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional request.FirebaseNotification firebase = 12;
 * @return {?proto.request.FirebaseNotification}
 */
proto.root.MetaData.prototype.getFirebase = function() {
  return /** @type{?proto.request.FirebaseNotification} */ (
    jspb.Message.getWrapperField(this, request_firebase_pb.FirebaseNotification, 12));
};


/**
 * @param {?proto.request.FirebaseNotification|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setFirebase = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearFirebase = function() {
  return this.setFirebase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasFirebase = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional request.AcceptCall accept_call = 13;
 * @return {?proto.request.AcceptCall}
 */
proto.root.MetaData.prototype.getAcceptCall = function() {
  return /** @type{?proto.request.AcceptCall} */ (
    jspb.Message.getWrapperField(this, request_accept_call_pb.AcceptCall, 13));
};


/**
 * @param {?proto.request.AcceptCall|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setAcceptCall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearAcceptCall = function() {
  return this.setAcceptCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasAcceptCall = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional request.CallRinging call_ringing = 14;
 * @return {?proto.request.CallRinging}
 */
proto.root.MetaData.prototype.getCallRinging = function() {
  return /** @type{?proto.request.CallRinging} */ (
    jspb.Message.getWrapperField(this, request_call_ringing_pb.CallRinging, 14));
};


/**
 * @param {?proto.request.CallRinging|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setCallRinging = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearCallRinging = function() {
  return this.setCallRinging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasCallRinging = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional request.ChangeMediaDomain change_media_domain = 15;
 * @return {?proto.request.ChangeMediaDomain}
 */
proto.root.MetaData.prototype.getChangeMediaDomain = function() {
  return /** @type{?proto.request.ChangeMediaDomain} */ (
    jspb.Message.getWrapperField(this, request_change_media_domain_pb.ChangeMediaDomain, 15));
};


/**
 * @param {?proto.request.ChangeMediaDomain|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setChangeMediaDomain = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearChangeMediaDomain = function() {
  return this.setChangeMediaDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasChangeMediaDomain = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional request.CheckPresence check_presence = 16;
 * @return {?proto.request.CheckPresence}
 */
proto.root.MetaData.prototype.getCheckPresence = function() {
  return /** @type{?proto.request.CheckPresence} */ (
    jspb.Message.getWrapperField(this, request_check_presence_pb.CheckPresence, 16));
};


/**
 * @param {?proto.request.CheckPresence|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setCheckPresence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearCheckPresence = function() {
  return this.setCheckPresence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasCheckPresence = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional request.CheckAndSetPresence check_and_set_presence = 17;
 * @return {?proto.request.CheckAndSetPresence}
 */
proto.root.MetaData.prototype.getCheckAndSetPresence = function() {
  return /** @type{?proto.request.CheckAndSetPresence} */ (
    jspb.Message.getWrapperField(this, request_check_and_set_presence_pb.CheckAndSetPresence, 17));
};


/**
 * @param {?proto.request.CheckAndSetPresence|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setCheckAndSetPresence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearCheckAndSetPresence = function() {
  return this.setCheckAndSetPresence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasCheckAndSetPresence = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional request.MediaRegister media_register = 18;
 * @return {?proto.request.MediaRegister}
 */
proto.root.MetaData.prototype.getMediaRegister = function() {
  return /** @type{?proto.request.MediaRegister} */ (
    jspb.Message.getWrapperField(this, request_media_register_pb.MediaRegister, 18));
};


/**
 * @param {?proto.request.MediaRegister|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setMediaRegister = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearMediaRegister = function() {
  return this.setMediaRegister(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasMediaRegister = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional request.LetsCall letsCall = 19;
 * @return {?proto.request.LetsCall}
 */
proto.root.MetaData.prototype.getLetscall = function() {
  return /** @type{?proto.request.LetsCall} */ (
    jspb.Message.getWrapperField(this, request_lets_call_pb.LetsCall, 19));
};


/**
 * @param {?proto.request.LetsCall|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setLetscall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearLetscall = function() {
  return this.setLetscall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasLetscall = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional request.RemoveRetainedChats remove_retained_chats = 20;
 * @return {?proto.request.RemoveRetainedChats}
 */
proto.root.MetaData.prototype.getRemoveRetainedChats = function() {
  return /** @type{?proto.request.RemoveRetainedChats} */ (
    jspb.Message.getWrapperField(this, request_remove_retained_chat_pb.RemoveRetainedChats, 20));
};


/**
 * @param {?proto.request.RemoveRetainedChats|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setRemoveRetainedChats = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearRemoveRetainedChats = function() {
  return this.setRemoveRetainedChats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasRemoveRetainedChats = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional request.MissedCall missed_call = 21;
 * @return {?proto.request.MissedCall}
 */
proto.root.MetaData.prototype.getMissedCall = function() {
  return /** @type{?proto.request.MissedCall} */ (
    jspb.Message.getWrapperField(this, request_missed_call_pb.MissedCall, 21));
};


/**
 * @param {?proto.request.MissedCall|undefined} value
 * @return {!proto.root.MetaData} returns this
*/
proto.root.MetaData.prototype.setMissedCall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.root.MetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.MetaData} returns this
 */
proto.root.MetaData.prototype.clearMissedCall = function() {
  return this.setMissedCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.MetaData.prototype.hasMissedCall = function() {
  return jspb.Message.getField(this, 21) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.root.Meta.prototype.toObject = function(opt_includeInstance) {
  return proto.root.Meta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.root.Meta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.Meta.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metaVersion: (f = msg.getMetaVersion()) && proto.root.MetaVersion.toObject(includeInstance, f),
    metaData: (f = msg.getMetaData()) && proto.root.MetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.root.Meta}
 */
proto.root.Meta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.root.Meta;
  return proto.root.Meta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.root.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.root.Meta}
 */
proto.root.Meta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 2:
      var value = new proto.root.MetaVersion;
      reader.readMessage(value,proto.root.MetaVersion.deserializeBinaryFromReader);
      msg.setMetaVersion(value);
      break;
    case 3:
      var value = new proto.root.MetaData;
      reader.readMessage(value,proto.root.MetaData.deserializeBinaryFromReader);
      msg.setMetaData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.root.Meta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.root.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.root.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.Meta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetaVersion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.root.MetaVersion.serializeBinaryToWriter
    );
  }
  f = message.getMetaData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.root.MetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string action = 1;
 * @return {string}
 */
proto.root.Meta.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.root.Meta} returns this
 */
proto.root.Meta.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MetaVersion meta_version = 2;
 * @return {?proto.root.MetaVersion}
 */
proto.root.Meta.prototype.getMetaVersion = function() {
  return /** @type{?proto.root.MetaVersion} */ (
    jspb.Message.getWrapperField(this, proto.root.MetaVersion, 2));
};


/**
 * @param {?proto.root.MetaVersion|undefined} value
 * @return {!proto.root.Meta} returns this
*/
proto.root.Meta.prototype.setMetaVersion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Meta} returns this
 */
proto.root.Meta.prototype.clearMetaVersion = function() {
  return this.setMetaVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Meta.prototype.hasMetaVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MetaData meta_data = 3;
 * @return {?proto.root.MetaData}
 */
proto.root.Meta.prototype.getMetaData = function() {
  return /** @type{?proto.root.MetaData} */ (
    jspb.Message.getWrapperField(this, proto.root.MetaData, 3));
};


/**
 * @param {?proto.root.MetaData|undefined} value
 * @return {!proto.root.Meta} returns this
*/
proto.root.Meta.prototype.setMetaData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Meta} returns this
 */
proto.root.Meta.prototype.clearMetaData = function() {
  return this.setMetaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Meta.prototype.hasMetaData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.root.Response.oneofGroups_ = [[3,4,5,6,7,8,9,10,11,12,13]];

/**
 * @enum {number}
 */
proto.root.Response.DataCase = {
  DATA_NOT_SET: 0,
  CHECK_QUEUE: 3,
  FIREBASE: 4,
  LETS_CALL: 5,
  CHECK_PRESENCE: 6,
  CHECK_AND_SET_PRESENCE: 7,
  MEDIA_REGISTER: 8,
  CHAT: 9,
  CHAT_ACK: 10,
  CHATS: 11,
  CHAT_REMOVED_ACK: 12,
  NEW_TALK: 13
};

/**
 * @return {proto.root.Response.DataCase}
 */
proto.root.Response.prototype.getDataCase = function() {
  return /** @type {proto.root.Response.DataCase} */(jspb.Message.computeOneofCase(this, proto.root.Response.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.root.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.root.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.root.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metaVersion: (f = msg.getMetaVersion()) && proto.root.MetaVersion.toObject(includeInstance, f),
    checkQueue: (f = msg.getCheckQueue()) && response_check_queue_pb.CheckQueue.toObject(includeInstance, f),
    firebase: (f = msg.getFirebase()) && response_firebase_pb.FirebaseNotification.toObject(includeInstance, f),
    letsCall: (f = msg.getLetsCall()) && response_lets_call_pb.LetsCall.toObject(includeInstance, f),
    checkPresence: (f = msg.getCheckPresence()) && response_check_presence_pb.CheckPresence.toObject(includeInstance, f),
    checkAndSetPresence: (f = msg.getCheckAndSetPresence()) && response_check_and_set_presence_pb.CheckAndSetPresence.toObject(includeInstance, f),
    mediaRegister: (f = msg.getMediaRegister()) && response_media_register_pb.MediaRegister.toObject(includeInstance, f),
    chat: (f = msg.getChat()) && response_chat_pb.Chat.toObject(includeInstance, f),
    chatAck: (f = msg.getChatAck()) && response_chat_pb.ChatACK.toObject(includeInstance, f),
    chats: (f = msg.getChats()) && response_get_chats_pb.GetChats.toObject(includeInstance, f),
    chatRemovedAck: (f = msg.getChatRemovedAck()) && response_chat_pb.ChatRemovedACK.toObject(includeInstance, f),
    newTalk: (f = msg.getNewTalk()) && response_new_talk_pb.NewTalk.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.root.Response}
 */
proto.root.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.root.Response;
  return proto.root.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.root.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.root.Response}
 */
proto.root.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 2:
      var value = new proto.root.MetaVersion;
      reader.readMessage(value,proto.root.MetaVersion.deserializeBinaryFromReader);
      msg.setMetaVersion(value);
      break;
    case 3:
      var value = new response_check_queue_pb.CheckQueue;
      reader.readMessage(value,response_check_queue_pb.CheckQueue.deserializeBinaryFromReader);
      msg.setCheckQueue(value);
      break;
    case 4:
      var value = new response_firebase_pb.FirebaseNotification;
      reader.readMessage(value,response_firebase_pb.FirebaseNotification.deserializeBinaryFromReader);
      msg.setFirebase(value);
      break;
    case 5:
      var value = new response_lets_call_pb.LetsCall;
      reader.readMessage(value,response_lets_call_pb.LetsCall.deserializeBinaryFromReader);
      msg.setLetsCall(value);
      break;
    case 6:
      var value = new response_check_presence_pb.CheckPresence;
      reader.readMessage(value,response_check_presence_pb.CheckPresence.deserializeBinaryFromReader);
      msg.setCheckPresence(value);
      break;
    case 7:
      var value = new response_check_and_set_presence_pb.CheckAndSetPresence;
      reader.readMessage(value,response_check_and_set_presence_pb.CheckAndSetPresence.deserializeBinaryFromReader);
      msg.setCheckAndSetPresence(value);
      break;
    case 8:
      var value = new response_media_register_pb.MediaRegister;
      reader.readMessage(value,response_media_register_pb.MediaRegister.deserializeBinaryFromReader);
      msg.setMediaRegister(value);
      break;
    case 9:
      var value = new response_chat_pb.Chat;
      reader.readMessage(value,response_chat_pb.Chat.deserializeBinaryFromReader);
      msg.setChat(value);
      break;
    case 10:
      var value = new response_chat_pb.ChatACK;
      reader.readMessage(value,response_chat_pb.ChatACK.deserializeBinaryFromReader);
      msg.setChatAck(value);
      break;
    case 11:
      var value = new response_get_chats_pb.GetChats;
      reader.readMessage(value,response_get_chats_pb.GetChats.deserializeBinaryFromReader);
      msg.setChats(value);
      break;
    case 12:
      var value = new response_chat_pb.ChatRemovedACK;
      reader.readMessage(value,response_chat_pb.ChatRemovedACK.deserializeBinaryFromReader);
      msg.setChatRemovedAck(value);
      break;
    case 13:
      var value = new response_new_talk_pb.NewTalk;
      reader.readMessage(value,response_new_talk_pb.NewTalk.deserializeBinaryFromReader);
      msg.setNewTalk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.root.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.root.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.root.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.root.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetaVersion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.root.MetaVersion.serializeBinaryToWriter
    );
  }
  f = message.getCheckQueue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      response_check_queue_pb.CheckQueue.serializeBinaryToWriter
    );
  }
  f = message.getFirebase();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      response_firebase_pb.FirebaseNotification.serializeBinaryToWriter
    );
  }
  f = message.getLetsCall();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      response_lets_call_pb.LetsCall.serializeBinaryToWriter
    );
  }
  f = message.getCheckPresence();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      response_check_presence_pb.CheckPresence.serializeBinaryToWriter
    );
  }
  f = message.getCheckAndSetPresence();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      response_check_and_set_presence_pb.CheckAndSetPresence.serializeBinaryToWriter
    );
  }
  f = message.getMediaRegister();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      response_media_register_pb.MediaRegister.serializeBinaryToWriter
    );
  }
  f = message.getChat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      response_chat_pb.Chat.serializeBinaryToWriter
    );
  }
  f = message.getChatAck();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      response_chat_pb.ChatACK.serializeBinaryToWriter
    );
  }
  f = message.getChats();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      response_get_chats_pb.GetChats.serializeBinaryToWriter
    );
  }
  f = message.getChatRemovedAck();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      response_chat_pb.ChatRemovedACK.serializeBinaryToWriter
    );
  }
  f = message.getNewTalk();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      response_new_talk_pb.NewTalk.serializeBinaryToWriter
    );
  }
};


/**
 * optional string action = 1;
 * @return {string}
 */
proto.root.Response.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MetaVersion meta_version = 2;
 * @return {?proto.root.MetaVersion}
 */
proto.root.Response.prototype.getMetaVersion = function() {
  return /** @type{?proto.root.MetaVersion} */ (
    jspb.Message.getWrapperField(this, proto.root.MetaVersion, 2));
};


/**
 * @param {?proto.root.MetaVersion|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setMetaVersion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearMetaVersion = function() {
  return this.setMetaVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasMetaVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional response.CheckQueue check_queue = 3;
 * @return {?proto.response.CheckQueue}
 */
proto.root.Response.prototype.getCheckQueue = function() {
  return /** @type{?proto.response.CheckQueue} */ (
    jspb.Message.getWrapperField(this, response_check_queue_pb.CheckQueue, 3));
};


/**
 * @param {?proto.response.CheckQueue|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setCheckQueue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearCheckQueue = function() {
  return this.setCheckQueue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasCheckQueue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional response.FirebaseNotification firebase = 4;
 * @return {?proto.response.FirebaseNotification}
 */
proto.root.Response.prototype.getFirebase = function() {
  return /** @type{?proto.response.FirebaseNotification} */ (
    jspb.Message.getWrapperField(this, response_firebase_pb.FirebaseNotification, 4));
};


/**
 * @param {?proto.response.FirebaseNotification|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setFirebase = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearFirebase = function() {
  return this.setFirebase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasFirebase = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional response.LetsCall lets_call = 5;
 * @return {?proto.response.LetsCall}
 */
proto.root.Response.prototype.getLetsCall = function() {
  return /** @type{?proto.response.LetsCall} */ (
    jspb.Message.getWrapperField(this, response_lets_call_pb.LetsCall, 5));
};


/**
 * @param {?proto.response.LetsCall|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setLetsCall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearLetsCall = function() {
  return this.setLetsCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasLetsCall = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional response.CheckPresence check_presence = 6;
 * @return {?proto.response.CheckPresence}
 */
proto.root.Response.prototype.getCheckPresence = function() {
  return /** @type{?proto.response.CheckPresence} */ (
    jspb.Message.getWrapperField(this, response_check_presence_pb.CheckPresence, 6));
};


/**
 * @param {?proto.response.CheckPresence|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setCheckPresence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearCheckPresence = function() {
  return this.setCheckPresence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasCheckPresence = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional response.CheckAndSetPresence check_and_set_presence = 7;
 * @return {?proto.response.CheckAndSetPresence}
 */
proto.root.Response.prototype.getCheckAndSetPresence = function() {
  return /** @type{?proto.response.CheckAndSetPresence} */ (
    jspb.Message.getWrapperField(this, response_check_and_set_presence_pb.CheckAndSetPresence, 7));
};


/**
 * @param {?proto.response.CheckAndSetPresence|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setCheckAndSetPresence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearCheckAndSetPresence = function() {
  return this.setCheckAndSetPresence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasCheckAndSetPresence = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional response.MediaRegister media_register = 8;
 * @return {?proto.response.MediaRegister}
 */
proto.root.Response.prototype.getMediaRegister = function() {
  return /** @type{?proto.response.MediaRegister} */ (
    jspb.Message.getWrapperField(this, response_media_register_pb.MediaRegister, 8));
};


/**
 * @param {?proto.response.MediaRegister|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setMediaRegister = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearMediaRegister = function() {
  return this.setMediaRegister(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasMediaRegister = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional response.Chat chat = 9;
 * @return {?proto.response.Chat}
 */
proto.root.Response.prototype.getChat = function() {
  return /** @type{?proto.response.Chat} */ (
    jspb.Message.getWrapperField(this, response_chat_pb.Chat, 9));
};


/**
 * @param {?proto.response.Chat|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setChat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearChat = function() {
  return this.setChat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasChat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional response.ChatACK chat_ack = 10;
 * @return {?proto.response.ChatACK}
 */
proto.root.Response.prototype.getChatAck = function() {
  return /** @type{?proto.response.ChatACK} */ (
    jspb.Message.getWrapperField(this, response_chat_pb.ChatACK, 10));
};


/**
 * @param {?proto.response.ChatACK|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setChatAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearChatAck = function() {
  return this.setChatAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasChatAck = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional response.GetChats chats = 11;
 * @return {?proto.response.GetChats}
 */
proto.root.Response.prototype.getChats = function() {
  return /** @type{?proto.response.GetChats} */ (
    jspb.Message.getWrapperField(this, response_get_chats_pb.GetChats, 11));
};


/**
 * @param {?proto.response.GetChats|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setChats = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearChats = function() {
  return this.setChats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasChats = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional response.ChatRemovedACK chat_removed_ack = 12;
 * @return {?proto.response.ChatRemovedACK}
 */
proto.root.Response.prototype.getChatRemovedAck = function() {
  return /** @type{?proto.response.ChatRemovedACK} */ (
    jspb.Message.getWrapperField(this, response_chat_pb.ChatRemovedACK, 12));
};


/**
 * @param {?proto.response.ChatRemovedACK|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setChatRemovedAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearChatRemovedAck = function() {
  return this.setChatRemovedAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasChatRemovedAck = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional response.NewTalk new_talk = 13;
 * @return {?proto.response.NewTalk}
 */
proto.root.Response.prototype.getNewTalk = function() {
  return /** @type{?proto.response.NewTalk} */ (
    jspb.Message.getWrapperField(this, response_new_talk_pb.NewTalk, 13));
};


/**
 * @param {?proto.response.NewTalk|undefined} value
 * @return {!proto.root.Response} returns this
*/
proto.root.Response.prototype.setNewTalk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.root.Response.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.root.Response} returns this
 */
proto.root.Response.prototype.clearNewTalk = function() {
  return this.setNewTalk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.root.Response.prototype.hasNewTalk = function() {
  return jspb.Message.getField(this, 13) != null;
};


goog.object.extend(exports, proto.root);
