import styles from './header.module.scss'
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg'
import DownloadBtn from '../../buttons/downloadbtn/DownloadBtn'
import { useEffect, useState } from 'react';
import { DeviceOS, cookiesLabel } from '../../../utils/Constants';
import Cookies from 'js-cookie';
const Header = () => {
  const [deviceOs, setDeviceOs] = useState('');
  useEffect(() => {
    // Get the user agent string
    const userAgent = window.navigator.userAgent;

    // Check if the user agent contains the string "Android"
    const isAndroid = /Android/.test(userAgent);

    // Check if the user agent contains the string "iPhone" or "iPad"
    const isIOS = /iPhone|iPad/.test(userAgent);

    if (isAndroid) {
      setDeviceOs(DeviceOS.ANDROID)
      Cookies.set(cookiesLabel.DEVICEOS, DeviceOS.ANDROID)

    } else if (isIOS) {
      setDeviceOs(DeviceOS.IOS)
      Cookies.set(cookiesLabel.DEVICEOS, DeviceOS.IOS)
    } else {
      setDeviceOs(DeviceOS.OTHER)
      Cookies.set(cookiesLabel.DEVICEOS, DeviceOS.OTHER)

    }
  }, []);
  return (
    <div className={styles.headerContainer}>
      <div className={styles.LogoContaner}>
        <Logo />
      </div>
      <div className={styles.downloadBtnContainer}>
        {(deviceOs === DeviceOS.ANDROID || deviceOs === DeviceOS.IOS) && <DownloadBtn device={deviceOs} element='header' />}
      </div>
    </div>
  )

}
export default Header