import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";

import { Encryption_Keys, cookiesLabel } from "./Constants";
import * as proto from '../protobuf/v1/proto_pb'
import { Chat, ChatMessageTopic, ChatTypeMessage } from '../protobuf/request/chat_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { v4 as uuidv4 } from 'uuid';
import { updateData } from '../services/requests';
export const decodeToken = (token) => {
  try {
    // Decode the token without 'jsonwebtoken'
    const payload = atob(token.split('.')[1]);
    return JSON.parse(payload);
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};


//encription function based on key
export const encryptText = (encriptingData, key) => {
  const encrypted = CryptoJS.AES.encrypt(encriptingData, key);
  return encrypted
};

//decription function based on key
export const decryptText = (encriptedData, key) => {
  const bytes = CryptoJS.AES.decrypt(encriptedData, key);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted
};


//SAVE TO COOKIES 
export const SaveInCookies = (accesToken, refreshToken, accountId, deviceid, sessionid, firebaseToken) => {
  const encriptedAccessToken = encryptText(accesToken, Encryption_Keys.ACCESS_TOKEN_key)
  const encriptedAccountId = encryptText(String(accountId), Encryption_Keys.ACCOUNT_ID_KEY)

  Cookies.set(cookiesLabel.ACCESSTOKEN, encriptedAccessToken.toString(), { expires: 20 })
  Cookies.set(cookiesLabel.REFRESHTOKEN, refreshToken, { expires: 20 })
  if (accountId) {
    Cookies.set(cookiesLabel.ACCONTID, encriptedAccountId.toString(), { expires: 20 })
  }

  if (deviceid) {
    Cookies.set(cookiesLabel.DEVICEID, deviceid, { expires: 20 })
  }
  if (sessionid) {
    Cookies.set(cookiesLabel.SESSIONID, sessionid, { expires: 20 })
  }
  if (firebaseToken) {
    Cookies.set(cookiesLabel.FIREBASETOKEN, firebaseToken, { expires: 20 })
  }
}

export const getFromCookies = (cookiesData, encriptionKey) => {
  const cookieGotData = Cookies.get(cookiesData)
  if (cookieGotData) {
    const decriptedData = decryptText(cookieGotData, encriptionKey)
    return decriptedData
  }

}

export const timeConvertor = (seconds, nanos) => {
  // Convert seconds and nanoseconds to milliseconds
  const milliseconds = seconds * 1000 + Math.floor(nanos / 1000000);

  // Create a new Date object
  const date = new Date(milliseconds);

  // Extract hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes to be always two digits
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Return the formatted time
  return `${formattedHours}:${formattedMinutes}`;
};
export const millisecondConvertor = (seconds, nanos) => {
  // Convert seconds and nanoseconds to milliseconds
  const milliseconds = seconds * 1000 + Math.floor(nanos / 1000000);
  return milliseconds
}
export function ShowTimeFormatted(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
export const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';


  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes; // pad minutes with leading zero if necessary

  return `${hours}:${minutes}`;
};
export const isPersianOrArabic = (text) => {
  const persianArabicRegex = /[\u0600-\u06FF\u0750-\u077F\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return persianArabicRegex.test(text);
}

//-------sending chat message function
export const sendingChatMessage = (accountId, partner_account_id, firsAppConfigTime, serverTimeStamp, message, setMessages) => {
  if (message.trim() !== '') {
    const currentTime = Date.now();
    // console.log('current time', currentTime);
    // console.log('serverTimeStamp', serverTimeStamp);
    // console.log('firsAppConfigTime', firsAppConfigTime);
    const sendingTimeStamp = (Number(currentTime) - Number(firsAppConfigTime)) + Number(serverTimeStamp)
    // console.log('sendingTimeStamp', sendingTimeStamp);
    const uuid = uuidv4()
    const time = Timestamp.fromDate(new Date())
    const dataSection = `{msg_senderId: ${accountId},msg_receiverId: ${partner_account_id},timestamp: ${sendingTimeStamp},msg: "${message}", uuid: ${uuid},"chatType": "MESSAGE"}`
    // Create a new instance of the Meta message
    const metaMessage = new proto.Meta();
    // Create a MetaVersion and MetaData messages
    const metaVersion = new proto.MetaVersion();
    metaVersion.setVersionNumber(1);
    const chatMessage = new Chat();
    chatMessage.setTopic(new ChatMessageTopic());
    chatMessage.getTopic().setChatId('chat123456');
    chatMessage.getTopic().setSenderId(accountId);
    chatMessage.getTopic().setReceiverId(partner_account_id);
    chatMessage.getTopic().setIsRetain(false);
    chatMessage.getTopic().setChatTypeMessage(ChatTypeMessage.SENT);
    chatMessage.setUuid(uuid);
    // chatMessage.setKey('key');
    chatMessage.setSenderPublicKeyIdentifier('key');
    chatMessage.setSenderPublicKey('key');
    chatMessage.setReceiverPublicKeyIdentifier('key');
    chatMessage.setData(dataSection);
    // chatMessage.setSign('signature');
    chatMessage.setTime(time);
    chatMessage.setVersion(1);
    const metaData = new proto.MetaData();
    metaData.setSenderId(accountId);
    metaData.setReceiverId(partner_account_id);
    metaData.setChat(chatMessage);

    // Set the fields for the Meta message
    metaMessage.setAction('CHAT');
    metaMessage.setMetaVersion(metaVersion);
    metaMessage.setMetaData(metaData);
    // Serialize the message to a buffer
    const buffer = metaMessage.serializeBinary();
    // Deserialize the buffer back to a Meta message
    const deserializedMetaMessage = proto.Meta.deserializeBinary(buffer);
    console.log('sending Message:', deserializedMetaMessage);
    // Convert the buffer to a Base64 string
    const base64Message = btoa(String.fromCharCode(...new Uint8Array(buffer)));
    // setMessages(prevMessages => [
    //   ...prevMessages,
    //   { text: `${message}`, sender: 'me', timestamp: sendingTimeStamp, showTime: formatTime(sendingTimeStamp), uuid: uuid, status: 'pending' }
    // ]);
    setMessages(prevMessages => {
      const newMessages = [...prevMessages, {type:'TEXT', text: `${message}`, sender: 'me', timestamp: sendingTimeStamp, showTime: formatTime(sendingTimeStamp), uuid: uuid, status: 'pending' }];
      return newMessages.sort((a, b) => a.timestamp - b.timestamp);
    });
    return base64Message
  }
}

//-------sending isTyping message function
export const sendIsTypingMessage = (accountId, partner_account_id, status) => {
  const uuid = uuidv4()
  const time = Timestamp.fromDate(new Date())
  const dataSection = `{chatInteraction_senderId: ${accountId},chatInteraction_receiverId: ${partner_account_id},status:"${status}",type:"IS_TYPING"}`
  // Create a new instance of the Meta message
  const metaMessage = new proto.Meta();
  // Create a MetaVersion and MetaData messages
  const metaVersion = new proto.MetaVersion();
  metaVersion.setVersionNumber(1);
  const chatMessage = new Chat();
  chatMessage.setTopic(new ChatMessageTopic());
  chatMessage.getTopic().setChatId('chat123456');
  chatMessage.getTopic().setSenderId(accountId);
  chatMessage.getTopic().setReceiverId(partner_account_id);
  chatMessage.getTopic().setIsRetain(false);
  chatMessage.getTopic().setChatTypeMessage(ChatTypeMessage.CHAT_INTERACTION);
  chatMessage.setUuid(uuid);
  // chatMessage.setKey('key');
  chatMessage.setSenderPublicKeyIdentifier('key');
  chatMessage.setSenderPublicKey('key');
  chatMessage.setReceiverPublicKeyIdentifier('key');
  chatMessage.setData(dataSection);
  // chatMessage.setSign('signature');
  chatMessage.setTime(time);
  chatMessage.setVersion(1);
  const metaData = new proto.MetaData();
  metaData.setSenderId(accountId);
  metaData.setReceiverId(partner_account_id);
  metaData.setChat(chatMessage);
  // Set the fields for the Meta message
  metaMessage.setAction('CHAT');
  metaMessage.setMetaVersion(metaVersion);
  metaMessage.setMetaData(metaData);
  // Serialize the message to a buffer
  const buffer = metaMessage.serializeBinary();
  // Deserialize the buffer back to a Meta message
  const deserializedMetaMessage = proto.Meta.deserializeBinary(buffer);
  console.log('is typing message:', deserializedMetaMessage);
  // Convert the buffer to a Base64 string
  const base64Message = btoa(String.fromCharCode(...new Uint8Array(buffer)));
  return base64Message
}

//-------sending delivered message function
export const sendingDeliveredMessage = (accountId, partner_account_id, sendingUUid) => {
  const uuid = uuidv4()
  const time = Timestamp.fromDate(new Date())
  const dataSection = `{deliver_senderId: ${accountId},deliver_receiverId: ${partner_account_id},uuids:["${sendingUUid}"]}`
  // Create a new instance of the Meta message
  const metaMessage = new proto.Meta();
  // Create a MetaVersion and MetaData messages
  const metaVersion = new proto.MetaVersion();
  metaVersion.setVersionNumber(1);
  const chatMessage = new Chat();
  chatMessage.setTopic(new ChatMessageTopic());
  chatMessage.getTopic().setChatId('chat123456');
  chatMessage.getTopic().setSenderId(accountId);
  chatMessage.getTopic().setReceiverId(partner_account_id);
  chatMessage.getTopic().setIsRetain(false);
  chatMessage.getTopic().setChatTypeMessage(ChatTypeMessage.DELIVER);
  chatMessage.setUuid(uuid);
  // chatMessage.setKey('key');/
  chatMessage.setSenderPublicKeyIdentifier('key');
  chatMessage.setSenderPublicKey('key');
  chatMessage.setReceiverPublicKeyIdentifier('key');
  chatMessage.setData(dataSection);
  // chatMessage.setSign('signature');
  chatMessage.setTime(time);
  chatMessage.setVersion(1);
  const metaData = new proto.MetaData();
  metaData.setSenderId(accountId);
  metaData.setReceiverId(partner_account_id);
  metaData.setChat(chatMessage);
  // Set the fields for the Meta message
  metaMessage.setAction('CHAT');
  metaMessage.setMetaVersion(metaVersion);
  metaMessage.setMetaData(metaData);
  // Serialize the message to a buffer
  const buffer = metaMessage.serializeBinary();
  // Deserialize the buffer back to a Meta message
  const deserializedMetaMessage = proto.Meta.deserializeBinary(buffer);
  console.log('sendingDeliveredMessage:', deserializedMetaMessage);
  // Convert the buffer to a Base64 string
  const base64Message = btoa(String.fromCharCode(...new Uint8Array(buffer)));
  return base64Message
}

//-------sending seen message function
export const sendingSeenMessage = (accountId, partner_account_id, sendingUUid) => {
  const uuid = uuidv4()
  const time = Timestamp.fromDate(new Date())
  const dataSection = `{seen_senderId: ${accountId},seen_receiverId: ${partner_account_id},timestamp:${sendingUUid}}`
  // Create a new instance of the Meta message
  const metaMessage = new proto.Meta();
  // Create a MetaVersion and MetaData messages
  const metaVersion = new proto.MetaVersion();
  metaVersion.setVersionNumber(1);
  const chatMessage = new Chat();
  chatMessage.setTopic(new ChatMessageTopic());
  chatMessage.getTopic().setChatId('chat123456');
  chatMessage.getTopic().setSenderId(accountId);
  chatMessage.getTopic().setReceiverId(partner_account_id);
  chatMessage.getTopic().setIsRetain(false);
  chatMessage.getTopic().setChatTypeMessage(ChatTypeMessage.SEEN);
  chatMessage.setUuid(uuid);
  // chatMessage.setKey('key');
  chatMessage.setSenderPublicKeyIdentifier('key');
  chatMessage.setSenderPublicKey('key');
  chatMessage.setReceiverPublicKeyIdentifier('key');
  chatMessage.setData(dataSection);
  // chatMessage.setSign('signature');
  chatMessage.setTime(time);
  chatMessage.setVersion(1);
  const metaData = new proto.MetaData();
  metaData.setSenderId(accountId);
  metaData.setReceiverId(partner_account_id);
  metaData.setChat(chatMessage);
  // Set the fields for the Meta message
  metaMessage.setAction('CHAT');
  metaMessage.setMetaVersion(metaVersion);
  metaMessage.setMetaData(metaData);
  // Serialize the message to a buffer
  const buffer = metaMessage.serializeBinary();
  // Deserialize the buffer back to a Meta message
  const deserializedMetaMessage = proto.Meta.deserializeBinary(buffer);
  console.log('sendingSeenMessage:', deserializedMetaMessage);
  // Convert the buffer to a Base64 string
  const base64Message = btoa(String.fromCharCode(...new Uint8Array(buffer)));
  return base64Message
}

//-------sending chat message function
export const sendingMediaChatMessage = (accountId, partner_account_id, firsAppConfigTime, serverTimeStamp, message, setMessages,mediaType,duration,size,caption,filekey,thumnailkey,filename,mediaUrl) => {
   const currentTime = Date.now();
     const sendingTimeStamp = (Number(currentTime) - Number(firsAppConfigTime)) + Number(serverTimeStamp)
     const uuid = uuidv4()
    const time = Timestamp.fromDate(new Date())
    const dataSection = `{msg_senderId: ${accountId},msg_receiverId: ${partner_account_id},timestamp: ${sendingTimeStamp},msg: "image", uuid: ${uuid},"chatType": "${mediaType}","mediaFiles":[{chatId:${uuid},mediaFileKey:"${filekey}",thumbnailFileKey:"${thumnailkey}",filename:"${filename}",caption:"${caption}",duration:${duration},size:${size},mediaType:"${mediaType}",status:"NOT_DOWNLOADED"}]}`
    // Create a new instance of the Meta message
    const metaMessage = new proto.Meta();
    // Create a MetaVersion and MetaData messages
    const metaVersion = new proto.MetaVersion();
    metaVersion.setVersionNumber(1);
    const chatMessage = new Chat();
    chatMessage.setTopic(new ChatMessageTopic());
    chatMessage.getTopic().setChatId('chat123456');
    chatMessage.getTopic().setSenderId(accountId);
    chatMessage.getTopic().setReceiverId(partner_account_id);
    chatMessage.getTopic().setIsRetain(false);
    chatMessage.getTopic().setChatTypeMessage(ChatTypeMessage.SENT);
    chatMessage.setUuid(uuid);
    // chatMessage.setKey('key');
    chatMessage.setSenderPublicKeyIdentifier('key');
    chatMessage.setSenderPublicKey('key');
    chatMessage.setReceiverPublicKeyIdentifier('key');
    chatMessage.setData(dataSection);
    // chatMessage.setSign('signature');
    chatMessage.setTime(time);
    chatMessage.setVersion(1);
    const metaData = new proto.MetaData();
    metaData.setSenderId(accountId);
    metaData.setReceiverId(partner_account_id);
    metaData.setChat(chatMessage);

    // Set the fields for the Meta message
    metaMessage.setAction('CHAT');
    metaMessage.setMetaVersion(metaVersion);
    metaMessage.setMetaData(metaData);
    // Serialize the message to a buffer
    const buffer = metaMessage.serializeBinary();
    // Deserialize the buffer back to a Meta message
    const deserializedMetaMessage = proto.Meta.deserializeBinary(buffer);
    console.log('media message Message:', deserializedMetaMessage);
    // Convert the buffer to a Base64 string
    const base64Message = btoa(String.fromCharCode(...new Uint8Array(buffer)));
    // setMessages(prevMessages => [
    //   ...prevMessages,
    //   { text: `${message}`, sender: 'me', timestamp: sendingTimeStamp, showTime: formatTime(sendingTimeStamp), uuid: uuid, status: 'pending' }
    // ]);
    // setMessages(prevMessages => {
    //   const newMessages = [...prevMessages, {type:`${mediaType}`, text: `${caption}`, sender: 'me', timestamp: sendingTimeStamp, showTime: formatTime(sendingTimeStamp), uuid: uuid, status: 'pending',mediaUrl:`${mediaUrl}` }];
    //   return newMessages.sort((a, b) => a.timestamp - b.timestamp);
    // });
    return base64Message
  
}

export const generateThumbnails = async (file) => {
   return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Handle image files
    if (file.type.startsWith('image/')) {
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          // Create two canvases: one for the regular thumbnail and one for the blurred thumbnail
          const canvas = document.createElement('canvas');
          const blurredCanvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const blurredCtx = blurredCanvas.getContext('2d');

          const thumbnailWidth = 200;
          // const thumbnailHeight = (img.height / img.width) * thumbnailWidth;
          const thumbnailHeight = 200;

          // Regular thumbnail
          canvas.width = thumbnailWidth;
          canvas.height = thumbnailHeight;
          ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

          // Blurred thumbnail
          blurredCanvas.width = thumbnailWidth;
          blurredCanvas.height = thumbnailHeight;
          blurredCtx.filter = 'blur(10px)'; // Apply blur filter
          blurredCtx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

          // Convert both canvases to blob and then to byte array
          canvas.toBlob((thumbnailBlob) => {
            blurredCanvas.toBlob((blurredThumbnailBlob) => {
              // Convert both blobs to byte arrays
              const regularReader = new FileReader();
              const blurredReader = new FileReader();

              regularReader.onloadend = () => {
                const regularArrayBuffer = regularReader.result;
                const regularByteArray = new Uint8Array(regularArrayBuffer);

                blurredReader.onloadend = () => {
                  const blurredArrayBuffer = blurredReader.result;
                  const blurredByteArray = new Uint8Array(blurredArrayBuffer);

                  resolve({
                    regularThumbnailByteArray: regularByteArray,
                    blurredThumbnailByteArray: blurredByteArray,
                  });
                };

                blurredReader.onerror = reject;
                blurredReader.readAsArrayBuffer(blurredThumbnailBlob);
              };

              regularReader.onerror = reject;
              regularReader.readAsArrayBuffer(thumbnailBlob);
            }, file.type);
          }, file.type);
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    }

    // Handle video files
    else if (file.type.startsWith('video/')) {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const canvas = document.createElement('canvas');
        const blurredCanvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const blurredCtx = blurredCanvas.getContext('2d');

        const thumbnailWidth = 100;
        const thumbnailHeight = (video.videoHeight / video.videoWidth) * thumbnailWidth;

        // Regular thumbnail
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        // Blurred thumbnail
        blurredCanvas.width = thumbnailWidth;
        blurredCanvas.height = thumbnailHeight;
        blurredCtx.filter = 'blur(10px)';

        video.currentTime = 1; // Capture frame at 1-second mark
        video.onseeked = () => {
          // Regular thumbnail
          ctx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);

          // Blurred thumbnail
          blurredCtx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);

          // Convert both canvases to blob and then to byte array
          canvas.toBlob((thumbnailBlob) => {
            blurredCanvas.toBlob((blurredThumbnailBlob) => {
              const regularReader = new FileReader();
              const blurredReader = new FileReader();
           
              regularReader.onloadend = () => {
                const regularArrayBuffer = regularReader.result;
                const regularByteArray = new Uint8Array(regularArrayBuffer);
                blurredReader.onloadend = () => {
                  const blurredArrayBuffer = blurredReader.result;
                  const blurredByteArray = new Uint8Array(blurredArrayBuffer);                
                  resolve({                    
                    regularThumbnailByteArray: regularByteArray,
                    blurredThumbnailByteArray: blurredByteArray,
                  });
                };

                blurredReader.onerror = reject;
                blurredReader.readAsArrayBuffer(blurredThumbnailBlob);
              };

              regularReader.onerror = reject;
              regularReader.readAsArrayBuffer(thumbnailBlob);
            }, file.type);
          }, file.type);
        };
      };
      video.onerror = reject;
    } else {
      reject(new Error('Unsupported file type'));
    }
  });
};

// export const generateThumbnails = async (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     // Handle image files
//     if (file.type.startsWith('image/')) {
//       reader.onload = (event) => {
//         const img = new Image();
//         img.src = event.target.result;

//         img.onload = () => {
//           // Create two canvases: one for the regular thumbnail and one for the blurred thumbnail
//           const canvas = document.createElement('canvas');
//           const blurredCanvas = document.createElement('canvas');
//           const ctx = canvas.getContext('2d');
//           const blurredCtx = blurredCanvas.getContext('2d');

//           const thumbnailWidth = 200;
//           const thumbnailHeight = 200;

//           // Regular thumbnail
//           canvas.width = thumbnailWidth;
//           canvas.height = thumbnailHeight;
//           ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

//           // Blurred thumbnail
//           blurredCanvas.width = thumbnailWidth;
//           blurredCanvas.height = thumbnailHeight;
//           blurredCtx.filter = 'blur(10px)';
//           blurredCtx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

//           // Convert both canvases to blob and then to byte array
//           canvas.toBlob((thumbnailBlob) => {
//             blurredCanvas.toBlob((blurredThumbnailBlob) => {
//               const regularReader = new FileReader();
//               const blurredReader = new FileReader();

//               regularReader.onloadend = () => {
//                 const regularArrayBuffer = regularReader.result;
//                 const regularByteArray = new Uint8Array(regularArrayBuffer);

//                 blurredReader.onloadend = () => {
//                   const blurredArrayBuffer = blurredReader.result;
//                   const blurredByteArray = new Uint8Array(blurredArrayBuffer);

//                   resolve({
//                     regularThumbnailByteArray: regularByteArray,
//                     blurredThumbnailByteArray: blurredByteArray,
//                   });
//                 };

//                 blurredReader.onerror = reject;
//                 blurredReader.readAsArrayBuffer(blurredThumbnailBlob);
//               };

//               regularReader.onerror = reject;
//               regularReader.readAsArrayBuffer(thumbnailBlob);
//             }, file.type);
//           }, file.type);
//         };
//       };
//       reader.onerror = reject;
//       reader.readAsDataURL(file);
//     }

//     // Handle video files
//     else if (file.type.startsWith('video/')) {
//       const video = document.createElement('video');
//       video.preload = 'metadata'; // Preload metadata for faster load

//       video.src = URL.createObjectURL(file);
//       video.onloadeddata = () => {
//         const canvas = document.createElement('canvas');
//         const blurredCanvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
//         const blurredCtx = blurredCanvas.getContext('2d');

//         const thumbnailWidth = 100;
//         const thumbnailHeight = (video.videoHeight / video.videoWidth) * thumbnailWidth;

//         canvas.width = thumbnailWidth;
//         canvas.height = thumbnailHeight;
//         blurredCanvas.width = thumbnailWidth;
//         blurredCanvas.height = thumbnailHeight;
//         blurredCtx.filter = 'blur(10px)';

//         video.currentTime = 1; // Capture frame at 1-second mark
//         video.onseeked = () => {
//           // Draw the frame on both regular and blurred canvases
//           ctx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);
//           blurredCtx.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);

//           // Convert both canvases to blob and then to byte array
//           canvas.toBlob((thumbnailBlob) => {
//             blurredCanvas.toBlob((blurredThumbnailBlob) => {
//               const regularReader = new FileReader();
//               const blurredReader = new FileReader();

//               regularReader.onloadend = () => {
//                 const regularArrayBuffer = regularReader.result;
//                 const regularByteArray = new Uint8Array(regularArrayBuffer);

//                 blurredReader.onloadend = () => {
//                   const blurredArrayBuffer = blurredReader.result;
//                   const blurredByteArray = new Uint8Array(blurredArrayBuffer);

//                   resolve({
//                     regularThumbnailByteArray: regularByteArray,
//                     blurredThumbnailByteArray: blurredByteArray,
//                   });
//                 };

//                 blurredReader.onerror = reject;
//                 blurredReader.readAsArrayBuffer(blurredThumbnailBlob);
//               };

//               regularReader.onerror = reject;
//               regularReader.readAsArrayBuffer(thumbnailBlob);
//             }, file.type);
//           }, file.type);
//         };
//       };
//       video.onerror = reject;
//     } else {
//       reject(new Error('Unsupported file type'));
//     }
//   });
// };




export const chunkFile = (file, chunkSize) => {
  const fileSize = file.size;
  const chunks = [];
  let start = 0;

  const readChunk = (chunk) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const byteArray = new Uint8Array(event.target.result);
        resolve({
          chunk: byteArray,
          size: chunk.size // Keep track of the chunk's size
        });
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(chunk);
    });
  };

  const chunkPromises = [];

  while (start < fileSize) {
    const end = Math.min(start + chunkSize, fileSize);
    const chunk = file.slice(start, end);
    chunkPromises.push(readChunk(chunk));
    start = end;
  }

  return Promise.all(chunkPromises);
};

//------------------------------------
const updateDataWithProgress = (url, chunk, onChunkProgress) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', url, true); // Use the PUT method to upload the chunk

    // Track upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        if (onChunkProgress) onChunkProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const etag = xhr.getResponseHeader('ETag');
        resolve(etag);
      } else {
        reject(new Error(`Error: ${xhr.status}`));
      }
    };

    xhr.onerror = () => reject(new Error('Upload failed'));

    // Send the chunk data
    xhr.send(chunk);
  });
};
//-------------------
// export const sendChunksToUrls = async (urls, chunks, onProgress) => {
//   if (urls.length !== chunks.length) {
//     console.error('Mismatch between number of URLs and file chunks!');
//     return;
//   }

//   const etags = [];
//   let uploadedSize = 0; // Track the total uploaded size
//   const totalSize = chunks.reduce((acc, { size }) => acc + size, 0); // Total size of all chunks

//   // Loop through the URLs and send chunks
//   for (let i = 0; i < urls.length; i++) {
//     const url = urls[i];
//     const { chunk, size } = chunks[i];

//     try {
//       // Pass a function to track the progress of each chunk upload
//       const etag = await updateDataWithProgress(url, chunk, (chunkProgress) => {
//         // Calculate the overall progress based on the chunk's progress
//         const chunkUploadedSize = (chunkProgress / 100) * size;
//         const overallUploadedSize = uploadedSize + chunkUploadedSize;

//         const overallPercentComplete = Math.round((overallUploadedSize / totalSize) * 100);

//         // Call the progress callback with the updated percentage
//         if (onProgress) onProgress(overallPercentComplete);
//       });

//       // console.log('ETag for chunk', i + 1, 'received:', etag);
//       etags.push({ part_number: i + 1, etag });

//       // Once the chunk is fully uploaded, add its size to uploadedSize
//       uploadedSize += size;

//     } catch (error) {
//       console.error('Error while sending chunk to URL:', url, error);
//     }
//   }

//   return etags; // Return the array of ETags
// };

//retrying 3 times
export const sendChunksToUrls = async (urls, chunks, onProgress) => {
  if (urls.length !== chunks.length) {
    console.error('Mismatch between number of URLs and file chunks!');
    return;
  }

  const etags = [];
  let uploadedSize = 0; // Track the total uploaded size
  const totalSize = chunks.reduce((acc, { size }) => acc + size, 0); // Total size of all chunks
  const MAX_RETRIES = 3;

  // Helper function to retry chunk upload
  const uploadChunkWithRetries = async (url, chunk, size, retries) => {
    let attempt = 0;

    while (attempt < retries) {
      try {
        const etag = await updateDataWithProgress(url, chunk, (chunkProgress) => {
          // Calculate the overall progress based on the chunk's progress
          const chunkUploadedSize = (chunkProgress / 100) * size;
          const overallUploadedSize = uploadedSize + chunkUploadedSize;
          const overallPercentComplete = Math.round((overallUploadedSize / totalSize) * 100);

          // Call the progress callback with the updated percentage
          if (onProgress) onProgress(overallPercentComplete);
        });

        return etag; // If upload succeeds, return the ETag
      } catch (error) {
        console.error(`Error while sending chunk to URL (Attempt ${attempt + 1}):`, url, error);
        attempt++;
        if (attempt >= retries) {
          throw new Error(`sending media fialed, please try again`);
        }
      }
    }
  };

  // Loop through the URLs and send chunks
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    const { chunk, size } = chunks[i];

    try {
      // Attempt to upload each chunk with retries
      const etag = await uploadChunkWithRetries(url, chunk, size, MAX_RETRIES);
      etags.push({ part_number: i + 1, etag });

      // Once the chunk is fully uploaded, add its size to uploadedSize
      uploadedSize += size;
    } catch (error) {
      console.error('Upload process failed after multiple attempts:', error);
      return; // Stop further execution if any chunk fails after retries
    }
  }

  return etags; // Return the array of ETags after all successful uploads
};


export const filedetection = (selectedFile) => {
  const fileType = selectedFile.type;
  
  if (fileType.includes('image')) {
    return 'PHOTO';
  }
  if (fileType.includes('video')) {
    return 'VIDEO';
  }
  if (fileType.includes('audio')) {
    return 'VOICE';
  }
  return null;
}
