import { useState, useEffect } from 'react';
import telephoneRingSound from '../../assets/other/ring.mp3';
function RingSound() {
  const [audio] = useState(new Audio(telephoneRingSound));
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const playAudio = () => {
      if (!isPlaying) {
        audio.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error('Error playing audio:', error);
          });
      }
    };

    const pauseAudio = () => {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      }
    };

    // Play the sound when the component mounts
    audio.addEventListener('canplay', playAudio);

    // Cleanup: Stop and remove the event listener when the component unmounts
    return () => {
      audio.removeEventListener('canplay', playAudio);
      pauseAudio();
      audio.currentTime = 0;
    };
  }, [audio, isPlaying]);


}

export default RingSound;
