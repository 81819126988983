import { isPersianOrArabic } from '../../utils/helperFunctions';
import { messageStatus } from '../chat/Chat';
import CircularProgress from '../CircularProgress/CircularProgress ';
import styles from './voicemessage.module.scss'
import musicImage from '../../assets/images/music.jpg'
const VoiceMessage = ({ msg,uploadProgress }) => {
    return (
      <div className={`${styles.message} ${msg.sender === 'me' ? styles.myMessage : styles.partnerMessage}`} style={{ textAlign: isPersianOrArabic(msg.text) ? 'right' : 'left' }}>
          {msg.status === 'pending' ? (
        <div className={styles.imageContainer}>
          <img src={musicImage} alt="Sent" className={styles.photo} width={200} height={200} />
          {/* Circular Progress in middle of the image */}
          <CircularProgress progress={uploadProgress} />
        </div>
      ) : <div className={styles.imageContainer}>
      <img src={musicImage} alt="Sent" className={styles.photo} width={200} height={200} />
      {/* Circular Progress in middle of the image */}
      <audio controls src={msg.originFile} className={styles.voice} />
    </div>}
        
        {msg.text ? msg.text.split('\n').map((line, index) => (
        <span key={index}>{line}</span>
      )) : ''}
        <p>{msg.showTime} <strong>{msg.sender === 'me' && messageStatus(msg.status)}</strong></p>
      </div>
    );
  };
  
  export default VoiceMessage;
  