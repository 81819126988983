import { configureStore, createSlice } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';

const initialState = {
  accessToken: '',
  refreshToken: '',
  accountId: '',
  deviceId: '',
  sessionId: '',
  mqtt: '',
  partner_account_id: '',
  partner_character_id: '',
  talk_id: '',
  firebaseToken: '',
  media_domain: '',
  partner_name: ''
}

const authSlice = createSlice(
  {
    name: 'auth',
    initialState,
    reducers: {
      setAccessTokens: (state, action) => {
        state.accessToken = action.payload;
      },
      setRefreshTokens: (state, action) => {
        state.refreshToken = action.payload;
      },
      setAccountId: (state, action) => {
        state.accountId = action.payload
      },
      setDeviceId: (state, action) => {
        state.deviceId = action.payload
      },
      setSessionId: (state, action) => {
        state.sessionId = action.payload
      },
      setFirebaseTokens: (state, action) => {
        state.firebaseToken = action.payload
      },
      setMqtt: (state, action) => {
        state.mqtt = action.payload
      },
      setPartnerAccountId: (state, action) => {
        state.partner_account_id = action.payload
      },
      setPartnerCharacterId: (state, action) => {
        state.partner_character_id = action.payload
      },
      setTalksId: (state, action) => {
        state.talk_id = action.payload
      },
      setMediaDomains: (state, action) => {
        state.media_domain = action.payload
      },
      setPartnerName: (state, action) => {
        state.partner_name = action.payload
      }
    }
  }
)

export const { setAccessTokens, setRefreshTokens, setAccountId, setDeviceId, setSessionId, setMqtt, setPartnerAccountId, setPartnerCharacterId, setTalksId, setFirebaseTokens, setMediaDomains, setPartnerName } = authSlice.actions;
const authReducer = authSlice.reducer;



// const ignoredActions = ['auth/setMqtt']; // Add your action types to be ignored

const store = configureStore({
  devTools: false,
  reducer: {
    auth: authReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk),
  devTools: {
    features: {
      // auth: false, // Disable DevTools for the 'auth' slice
      ignoredActions: ['auth/*']
    },
  },
});

export default store;