import React from 'react';
import styles from './istyping.module.scss';

const IsTyping = () => {
  return (
    <div className={styles.typingContainer}>

      <span className={styles.typingEllipsis}>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
      <span className={styles.typingText}> is typing</span>
    </div>
  );
};

export default IsTyping;